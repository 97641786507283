// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./intro.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./menu.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./meal.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../node_modules/postcss-loader/src/index.js??postcss!./meals.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*Global styles*/\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nh1, h2, h3, h4, h5 {\n    font-family: 'Playfair Display', serif;\n    font-weight: 900;\n}", "",{"version":3,"sources":["webpack://src/css/style.css"],"names":[],"mappings":"AAOA,gBAAgB;AAChB;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,sCAAsC;IACtC,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');\n@import './intro.css';\n@import './menu.css';\n@import './meal.css';\n@import './meals.css';\n\n\n/*Global styles*/\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nh1, h2, h3, h4, h5 {\n    font-family: 'Playfair Display', serif;\n    font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
